
import {
  IonContent,
  IonPage,
  IonCard,
  IonDatetime,
  IonItem,
  IonModal,
  IonList,
  IonInput,
  IonButton,
  IonChip,
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
  IonIcon,
  onIonViewWillEnter

} from '@ionic/vue';
import { defineComponent, ref, reactive, computed } from 'vue';
import { useClose } from '@/api/services/close';
import { setDateFormat } from '@/utils/dateFormatter';
import { calendar } from 'ionicons/icons';
import { useStore } from 'vuex';
import { users } from '@/api/services/user';


export default defineComponent({
  components: {
    IonContent,
    IonPage,
    IonDatetime,
    IonItem,
    IonCard,
    IonList,
    IonButton,
    IonGrid,
    IonRow,
    IonCol,
    IonChip,
    IonModal,
    IonInput,
    IonIcon,
    IonLabel,
  },
  setup() {
    const store = useStore();
    const auth = computed(() => store.getters['auth/getData']);
    const { saveLog } = users();

    onIonViewWillEnter(async () => {
      saveLog(auth.value.id, '거래처마감확인');
    });

    const form = reactive({
      modalState: false
    });
    const form2 = reactive({
      disable: true
    });
    async function onWillDismiss() {
      form.modalState = false;
    }
    async function modalChange() {
      form.modalState = true;
    }
    const frDate = ref(setDateFormat(new Date(), 'YYYY-MM')); //ref('2023-01-01');
    const toDate = ref(setDateFormat(new Date(), 'YYYY-MM'));
    const isOpen = ref(false);
    const {
      LIMIT,
      next,
      items,
      fetchDatas,
      insertSign,
      deleteSign,
      isConnected,
      insertSignAll
    } = useClose();
    // const emitter = inject<Emitter<Record<EventType, unknown>>>('emitter');
    // if (emitter) {
    //   emitter.on('fetchClose', () => {
    //     fetchDatas(frDate.value.substring(0, 8).replaceAll('-', ''));
    //   });
    // }
    async function listSearch() {
      await fetchDatas(frDate.value.substring(0, 8).replaceAll('-', ''));
      if (items.value.length != 0) {
        form2.disable = false
      } else {
        form2.disable = true
      }

    }
    // async function itemClick(item: any) {

    //   form.modalState = true;
    //   const sign = item
    //   const modal = await modalController.create({
    //     component: Modal,
    //     cssClass: 'my-modal-class4',
    //     componentProps: {
    //       signItem: sign
    //     }, backdropDismiss: true
    //   });
    //   modal.present();
    //   const { data, role } = await modal.onWillDismiss();
    //   if (role === 'confirm') {
    //     await fetchDatas(frDate.value.substring(0, 8).replaceAll('-', ''));
    //   } else if (role == 'clear') {
    //     await fetchDatas(frDate.value.substring(0, 8).replaceAll('-', ''));

    //   form.modalState = false;
    // }
    // }
    async function itemClick(item: any) {
      const sign = item
      if (sign.endYn == 'N') {
        insertSign(sign)
      } else {
        deleteSign(sign)
      }
    }

    async function itemAllClick(item: any) {
      await insertSignAll(item,frDate.value.substring(0, 8).replaceAll('-', ''));
    }

    return {
      LIMIT,
      next,
      items,
      frDate,
      toDate,
      calendar,
      fetchDatas,
      listSearch,
      isOpen,
      insertSign,
      deleteSign,
      setDateFormat,
      itemClick,
      isConnected,
      form,
      onWillDismiss,
      modalChange,
      form2,
      itemAllClick,
      auth,
    };
  }
});
