<template>
  <ion-page>
    <app-header back="user" title="거래처 마감 확인" />
    <ion-card>
      <section>
        <ion-label color="dark">조회년월</ion-label>
        <ion-input class="calendar" v-model="frDate" id="frdate" readonly @click="modalChange"><ion-icon :icon="calendar"></ion-icon></ion-input>
        <ion-modal trigger="frdate" @willDismiss="onWillDismiss">
          <ion-datetime class="dateitem" id="datetime" display-format="YYYY-MM" v-model="frDate" presentation="month-year"
            :show-default-buttons="true"></ion-datetime>
        </ion-modal>
      </section>
      <ion-grid>
        <ion-row class="ion-justify-content-start" >
          <ion-col size='4'><ion-button expand="block" color="medium" @click="listSearch">조회</ion-button></ion-col>
          <ion-col size='4'><ion-button expand="block" :disabled="auth.auth.includes('4') == true || auth.auth.includes('6') == true ? false : true"
              @click="itemAllClick('Y')">일괄확인</ion-button></ion-col>
          <ion-col size='4'><ion-button @click="itemAllClick('N')" expand="block" :disabled="auth.auth.includes('4') == true || auth.auth.includes('6') == true ? false : true" color="danger">일괄취소</ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-card>
    <ion-item>
      <ion-grid>
        <ion-row class="ion-justify-content-start">
          <ion-col size='6'>청구처<br />착지<br />품목</ion-col>
          <ion-col size='3'>출하중량<br />
            <p style="color:red; margin: 0%;">마감중량</p>선출하
          </ion-col>
          <ion-col size='3' class="header-center">마감확인</ion-col>
        </ion-row>
      </ion-grid>
    </ion-item>
    <ion-content>
      <div>
        <ion-list v-for="item in items" :key="item.sq">
          <ion-item >
            <ion-grid :style="{ 'background-color': item.endYn === '' ? '#dfdfdf' : 'white' }">
              <ion-row class="ion-justify-content-start">
                <ion-col size='6'>{{ item.saleName }}<br />{{ item.dplaceName }}<br />{{ item.itnm }}</ion-col>
                <ion-col size='3'>{{ item.saleQunt.toLocaleString('ko-KR', {
                  maximumFractionDigits:
                    5
                }) }}
                  <p style="color:red; margin: 0%;">{{
                    item.taxQunt.toLocaleString('ko-KR', {
                      maximumFractionDigits:
                        5
                    })
                  }}</p>
                  {{ item.sunQunt.toLocaleString('ko-KR', {
                    maximumFractionDigits:
                      5
                  }) }}
                </ion-col>
                <ion-col class ="header-center"  style="margin: auto;" >                 
                  <ion-chip v-if="item.endYn == 'Y'" color="primary"
                    :disabled="auth.auth.includes('4') == true || auth.auth.includes('6') == true ? false : true"
                    @click="itemClick(item)">확인</ion-chip>
                  <ion-chip v-else-if="item.endYn == 'N'" color="danger"
                    :disabled="auth.auth.includes('4') == true || auth.auth.includes('6') == true ? false : true"
                    @click="itemClick(item)">미확인</ion-chip>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-item>
        </ion-list>
      </div>
    </ion-content>
  </ion-page>
</template>
<script lang="ts">
import {
  IonContent,
  IonPage,
  IonCard,
  IonDatetime,
  IonItem,
  IonModal,
  IonList,
  IonInput,
  IonButton,
  IonChip,
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
  IonIcon,
  onIonViewWillEnter

} from '@ionic/vue';
import { defineComponent, ref, reactive, computed } from 'vue';
import { useClose } from '@/api/services/close';
import { setDateFormat } from '@/utils/dateFormatter';
import { calendar } from 'ionicons/icons';
import { useStore } from 'vuex';
import { users } from '@/api/services/user';


export default defineComponent({
  components: {
    IonContent,
    IonPage,
    IonDatetime,
    IonItem,
    IonCard,
    IonList,
    IonButton,
    IonGrid,
    IonRow,
    IonCol,
    IonChip,
    IonModal,
    IonInput,
    IonIcon,
    IonLabel,
  },
  setup() {
    const store = useStore();
    const auth = computed(() => store.getters['auth/getData']);
    const { saveLog } = users();

    onIonViewWillEnter(async () => {
      saveLog(auth.value.id, '거래처마감확인');
    });

    const form = reactive({
      modalState: false
    });
    const form2 = reactive({
      disable: true
    });
    async function onWillDismiss() {
      form.modalState = false;
    }
    async function modalChange() {
      form.modalState = true;
    }
    const frDate = ref(setDateFormat(new Date(), 'YYYY-MM')); //ref('2023-01-01');
    const toDate = ref(setDateFormat(new Date(), 'YYYY-MM'));
    const isOpen = ref(false);
    const {
      LIMIT,
      next,
      items,
      fetchDatas,
      insertSign,
      deleteSign,
      isConnected,
      insertSignAll
    } = useClose();
    // const emitter = inject<Emitter<Record<EventType, unknown>>>('emitter');
    // if (emitter) {
    //   emitter.on('fetchClose', () => {
    //     fetchDatas(frDate.value.substring(0, 8).replaceAll('-', ''));
    //   });
    // }
    async function listSearch() {
      await fetchDatas(frDate.value.substring(0, 8).replaceAll('-', ''));
      if (items.value.length != 0) {
        form2.disable = false
      } else {
        form2.disable = true
      }

    }
    // async function itemClick(item: any) {

    //   form.modalState = true;
    //   const sign = item
    //   const modal = await modalController.create({
    //     component: Modal,
    //     cssClass: 'my-modal-class4',
    //     componentProps: {
    //       signItem: sign
    //     }, backdropDismiss: true
    //   });
    //   modal.present();
    //   const { data, role } = await modal.onWillDismiss();
    //   if (role === 'confirm') {
    //     await fetchDatas(frDate.value.substring(0, 8).replaceAll('-', ''));
    //   } else if (role == 'clear') {
    //     await fetchDatas(frDate.value.substring(0, 8).replaceAll('-', ''));

    //   form.modalState = false;
    // }
    // }
    async function itemClick(item: any) {
      const sign = item
      if (sign.endYn == 'N') {
        insertSign(sign)
      } else {
        deleteSign(sign)
      }
    }

    async function itemAllClick(item: any) {
      await insertSignAll(item,frDate.value.substring(0, 8).replaceAll('-', ''));
    }

    return {
      LIMIT,
      next,
      items,
      frDate,
      toDate,
      calendar,
      fetchDatas,
      listSearch,
      isOpen,
      insertSign,
      deleteSign,
      setDateFormat,
      itemClick,
      isConnected,
      form,
      onWillDismiss,
      modalChange,
      form2,
      itemAllClick,
      auth,
    };
  }
});
</script>
<style scoped>
#container {
  margin: 0 auto;
  max-width: 800px;
  /* id="container" */
}

ion-label {
  margin: auto;
}

section {
  border-bottom: 1px solid #cccccc;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  width: 100%;
}

ion-icon {
  font-size: 20px;
}

ion-row {
  font-size: 0.7rem;
}

ion-col {
  padding: 0;
}

ion-grid {
  padding: 5px;
}

ion-list {
  padding: 0;
}

ion-chip {
  font-size: 0.8rem;
  margin: 0%;
}

.header-center {
  text-align: center;
  margin: 0 auto;
}

ion-card {
  margin: 0px;
  border-radius: 0px;
}

ion-input {
  text-align: center;
  color: black;
}
ion-item {
  padding: 0px;
  --inner-padding-end: 0px;
}
section {
  border-bottom: 1px solid #cccccc;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  width: 100%;
}

#signature-wrap {
  margin: 0.5em;
  padding: 1em;
}

.signature-pad {
  margin: 0 auto;
}

.signed {
  position: absolute;
  right: 15px;
}

.btn-wrap {
  margin: 0em;
}

.sign-tooltip {
  margin: 0.5em;
  color: #aaa;
}</style>