import { ref, Ref } from 'vue';
import { $api, $loading, $upload } from '@/api/index';
import { toast } from '@/utils/toast';
import { setDateFormat } from '@/utils/dateFormatter';

export interface CloseY {
  sq: string;
  yymm: string;
  saleCode: string;
  saleName: string;
  dplaceCode: string;
  dplaceName: string;
  itnmcode: string;
  itnm: string;
  saleQunt: number;
  taxQunt: number;
  sunQunt: number;
  endynNm: string;
  endYn: string;
}

export function useClose() {
  const items: Ref<CloseY[]> = ref([]);
  const year = ref(setDateFormat(new Date(), 'YYYY'));
  const month = ref(setDateFormat(new Date(), 'MM'));
  const LIMIT = 10;
  const page = ref(1);
  const next = ref(LIMIT);
  const isConnected = ref(true);

  async function fetchDatas(frDate: string) {
    await $loading(`/close?frDate=${frDate}`, 'POST').then(
      (res: any) => {
        if (res.data == ''){
          items.value = [];
          isConnected.value = true;
          toast('데이터가 존재하지 않습니다.'); 
        } else {
          items.value = null;
          items.value = res.data;
          isConnected.value = false;
        }
        items.value = res.data;
      }
    );
  }


  async function insertSign(data: any) {
    const fm = new FormData();
    fm.append('yymm', data.yymm);
    fm.append('saleCode', data.saleCode);
    fm.append('dplaceCode', data.dplaceCode);
    fm.append('itnmcode', data.itnmcode);
    fm.append('taxQunt', data.taxQunt);
    fm.append('sunQunt', data.sunQunt);
    await $upload('/close/sign', fm).then((res: any) => {
      if (res.status == 200) {
        toast('마감 완료');
        items.value = res.data;
      }
    });
  }

  async function deleteSign(data: any) {
    const fm = new FormData();
    fm.append('yymm', data.yymm);
    fm.append('saleCode', data.saleCode);
    fm.append('dplaceCode', data.dplaceCode);
    fm.append('itnmcode', data.itnmcode);
    fm.append('taxQunt', data.taxQunt);
    fm.append('sunQunt', data.sunQunt);
    await $upload('/close/delete', fm).then((res: any) => {
      if (res.status == 200) {
        toast('취소 완료');
        items.value = res.data;
      }
    });
  }
  async function insertSignAll(mode: string,frDate: string) {
    await $loading(`/close/signAll?mode=${mode}&frDate=${frDate}`, 'POST').then(
      (res: any) => {
        if (res.data == ''){
          items.value = [];
          toast('데이터가 존재하지 않습니다.'); 
        } else {
          items.value = null;
          items.value = res.data;
          toast('일괄처리가 완료되었습니다.'); 
        }
        
      }
    );
  }

  return {
    LIMIT,
    next,
    items,
    fetchDatas,
    insertSign,
    deleteSign,
    year,
    month,
    isConnected,
    insertSignAll
  };
}
